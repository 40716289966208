<template>
	<div class="chargement" :style="'transition: width '+duree+'s ease;width:'+width+'%'"></div>
</template>
<script>
	export default {
		data() {
			return {
				width:100,
				duree:0
			}
		},
		mounted() {
			this.$bus.$on('commencer-chargement',this.commercerChargement);
		},
		methods : {
			commercerChargement(slide) {
				this.duree=0;
				this.width=100;
				setTimeout(() => {
					this.duree=slide.duree-1;
					this.width=0;
				},1000)
			}
		}
	}
</script>
<style>
.chargement {
	z-index: 1;
	position:fixed;
	top:0;
	height: 4px;
	background-color: white;
	border-right: 1px solid #333;
	transition: width 0.5s;
}
</style>